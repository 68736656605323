import React from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';
import config from '../../config';
const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        <ul className="actions special">
          <li>
            <Scroll type="id" element="one">
              <a href="/#" className="button primary">
                Explore
              </a>
            </Scroll>
          </li>
        </ul>
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">
          Learn More
        </a>
      </Scroll>
    </section>

    <section id="one" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>
            Structured Serendipity
          </h2>
          <p>
            Explore with a group of curious, humble, and playful leaders.
            <br />
            Wander different paths along the same trail to discover new ways of thinking. 
          </p>
        </header>
        <ul className="icons major">
          <li>
            <span className="icon fa-sun major style1">
              <span className="label">Think</span>
            </span>
          </li>
          <li>
            <span className="icon fa-star major style2">
              <span className="label">Connect</span>
            </span>
          </li>
          <li>
            <span className="icon solid fa-heart major style3">
              <span className="label">Lead</span>
            </span>
          </li>
        </ul>
      </div>
    </section>

    <section id="two" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <img src={pic1} alt="" />
        </div>
        <div className="content">
          <h2>
            Uncover
          </h2>
          <p>
            By clearing your mind, breaking patterns, and gaining a new perspective,
you will deviate from the normal day-to-day to transform the impact you make as a leader.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic2} alt="" />
        </div>
        <div className="content">
          <h2>
            Unlearn
          </h2>
          <p>
            We’ll explore together. Get lost in unfiltered conversations to develop organic relationships, and be reminded of what having space to think really feels like.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic3} alt="" />
        </div>
        <div className="content">
          <h2>
            Unlock
          </h2>
          <p>
          Moments of introspection allow us to process our inner thoughts, give us the headspace to make uncommon connections, and help us steer our lives toward the best path possible.
          </p>
        </div>
      </section>
    </section>

    <section id="cta" className="wrapper style4">
      <div className="inner">
        <header>
          <h2>Join us for an unforgettable experience</h2>
          <p>
            Interested? Let us know a little more about you and we'll get you more information about upcoming opportunities.
          </p>
          <p>
            <a href="https://sidetrek.typeform.com/to/OWyz8x" className="button fit primary">
              Apply
            </a>
          </p>
        </header>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
